<template>
  <div>
    <h1 class="mb-4">Bundles</h1>
    <v-toolbar
      dense
      dark
    >
      <v-text-field
        name="searchfield"
        prepend-icon="mdi-table-search"
        v-model="search"
        clearable
        hide-details
      >
      </v-text-field>
      <v-spacer></v-spacer>
      <v-toolbar-items> </v-toolbar-items>
    </v-toolbar>
    <v-data-table
      :loading="pending"
      :headers="filteredHeaders"
      :items="payload"
      :options.sync="options"
      :search="search"
      :mobile-breakpoint="0"
      :footer-props="{ itemsPerPageOptions: [10, 30, 50, 100, 500] }"
      class="elevation-1"
      item-key="id"
      sort-by="percentage"
      sort-desc
    >
      <template v-slot:[`item.bundle`]="{ item }">
        <v-chip
          small
          outlined
        >
          <v-icon left>mdi-view-dashboard-outline</v-icon>{{ item.bundle }}
        </v-chip>
      </template>
      <template v-slot:[`item.operator`]="{ item }">
        <img
          :src="getImgUrl(item.operator)"
          class="pr-3"
          style="width:72px;max-height:24px;"
        />
      </template>
      <template v-slot:[`item.percentage`]="{ item }">
        <v-layout
          align-center
          justify-end
        >
          <v-progress-linear
            :value="item.percentage"
            class="mr-2"
            :color="percentageToHsl(item.percentage, 120, 0)"
            height="18"
          >
            <span class="ml-2">{{ item.percentage }}%</span>
            <template v-slot="{ value }">
              <strong>{{ Math.ceil(value) }}%</strong>
            </template>
          </v-progress-linear>
        </v-layout>
      </template>
      <template v-slot:[`item.last_usage`]="{ item }">
        {{ item.last_usage | datetime }}
      </template>
      <template v-slot:[`item.trigger_mail`]="{ item }">
        <v-tooltip
          v-if="item.trigger_mail"
          bottom
          offset-overflow
          max-width=340
        >
          <template v-slot:[`activator`]="{ on }">
            <v-icon
              v-on="on"
              color="primary"
            >mdi-email</v-icon>
          </template>
          <ul>
            <li
              v-for="(email,i) in item.trigger_mail.split(',')"
              :key="i"
            >
              {{email}}
            </li>
          </ul>

        </v-tooltip>
        <span v-else>
          <v-icon color="gray">mdi-email-off</v-icon>
        </span>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-tooltip right>
          <template v-slot:[`activator`]="{ on }">
            <v-icon
              @click="gotoOverview(item.bundle, item.customer_id, item.operator)"
              v-on="on"
            >
              mdi-sim
            </v-icon>
          </template>
          <span>Show cards in Overview</span>
        </v-tooltip>
      </template>
      <v-alert
        slot="no-results"
        :value="true"
        color="info"
        icon="mdi-magnify-close"
        class="mt-4"
        dark
      >
        There are no results that match your search.
      </v-alert>
    </v-data-table>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
    data() {
        return {
            headers: [
                { text: 'Action', value: 'action', align: 'center', sortable: false },
                {
                    text: 'Bundle',
                    align: 'left',
                    value: 'bundle',
                },
                { text: 'Operator', value: 'operator' },
                { text: 'Percentage', value: 'percentage', width: '220px' },
                { text: 'Total Cards', value: 'total_cards' },
                { text: 'Total Usage', value: 'total_usage' },
                { text: 'Customer', value: 'customer_name', advanced: true },
                { text: 'Email', value: 'trigger_mail' },
                { text: 'Last Usage', value: 'last_usage' },
            ],
            options: {},
            search: null,
        }
    },
    computed: {
        ...mapState('api', {
            pending: (state) => state.calls.default.pending,
            success: (state) => state.calls.default.success,
            message: (state) => state.calls.default.message,
            payload: (state) => state.calls.default.payload,
        }),
        ...mapState('session', {
            advanced_view: (state) => state.rules.advanced_view || false,
        }),
        filteredHeaders() {
            if (this.advanced_view) {
                return this.headers
            } else {
                return this.headers.filter((h) => h.advanced != true)
            }
        },
    },
    methods: {
        getImgUrl(img) {
            img = img.replace('!', '')
            return '/img/' + img.toLowerCase() + '.svg'
        },
        percentageToColor(value) {
            const hue = Math.round(value)
            return ['hsl(', hue, ', 100%, 50%)'].join('')
        },
        percentageToHsl(percentage, hue0, hue1) {
            var hue = Math.min(percentage / 100, 1) * (hue1 - hue0) + hue0
            return 'hsl(' + hue + ', 90%, 50%)'
        },
        gotoOverview(bundle, customer_id, operator) {
            this.$router.push({
                name: 'simcards',
                query: {
                    filter: `bundle:=${bundle},customer_id:=${customer_id},operator:=${operator},status:!=retired,start_date:!=null`,
                    sort_by: 'data_usage',
                    descending: 'true',
                },
            })
        },
    },
    created() {
        this.$store.dispatch('api/call', {
            url: '/bundles',
        })
    },
}
</script>
